import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import Loader from 'react-loaders'
import { useEffect, useState } from 'react'
import NovaScanImage from '../../assets/images/novascan.png'
import UraImage from '../../assets/images/uw.png'
import DalsaImage from '../../assets/images/tdalsa.png'
import IgemImage from '../../assets/images/iGEM.png'
import GemImage from '../../assets/images/gem.png'
import AttorneyImage from '../../assets/images/omat.png'

const Career = () => {
    const [letterClass, setLetterClass] = useState('text-animate')

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)

        return () => clearTimeout(timeout)
    }, [])

    const experiences = [
        {
            position: 'Data Analyst Intern',
            company: 'NovaScan \u00B7 Internship',
            location: 'Chicago, IL',
            date: 'May 2024 - Aug 2024',
            description: [
                'Researched Cole Relation Frequency for cancer detection using tissue bioimpedance measurements.',
                'Remodelled a linear regression machine learning algorithm to differentiate between malignant and benign samples, improving skin cancer detection accuracy.',
                'Optimized the data repository by transitioning patient scans to a cloud-based database, reducing data retrieval time.',
                'Minimized data loss by developing automated backup scripts, ensuring high data availability.'
            ],
            image: NovaScanImage,
            imageClass: 'novascan-image'
        },
        {
            position: 'Undergraduate Research Assistant',
            company: 'University of Waterloo \u00B7 Part-time',
            location: 'Waterloo, ON',
            date: 'Jan 2024 - Apr 2024',
            description: [
                'Enhanced data collection and analysis using Vicon motion capture cameras, IMUs, EMG sensors, and OpenSim for gait research.',
                'Configured force plates and an exoskeleton with Simulink to analyze spatiotemporal variations in gait, improving analysis accuracy.',
                'Developed an EMG signal filtering and smoothing algorithm using various techniques to reduce noise significantly.',
                'Applied biomechanical and kinematic analysis methods to assess gait stability metrics.'
            ],
            image: UraImage,
            imageClass: 'ura-image'
        },
        {
            position: '2D Image Sensor Test and Image Processing Developer',
            company: 'Teledyne DALSA \u00B7 Internship',
            location: 'Waterloo, ON',
            date: 'Sep 2023 - Dec 2023',
            description: [
                'Automated image processing tasks using Python and MATLAB scripts, significantly improving efficiency.',
                'Built test platforms integrating PCBA, image analysis software, and environmental chambers for comprehensive data collection.',
                'Applied statistical methods according to EMVA 1288 standards for thorough analysis of CMOS and CCD sensors.',
                'Engineered a correction simulation pipeline based on FPGA processing specifications for image sensors.',
                'Developed a polynomial correction function that reduced non-linearity in an image sensor.'
            ],
            image: DalsaImage,
            imageClass: 'dalsa-image'
        },
        {
            position: 'Math and Modelling Team Member and Lead',
            company: 'Waterloo iGEM \u00B7 Design Team',
            location: 'Waterloo, ON',
            date: 'Feb 2022 - Dec 2023',
            description: [
                'Led a team through the iterative engineering design process to model the bacterial dynamics, viral dynamics, plant health and susceptibility, and RNAi mechanism solution for TSWV in tomato plants.',
                'Mentored team members to enhance technical proficiency in programming and modelling techniques including ODEs, hill functions, quantification, bioinformatics, genome engineering.',
                'Derived mathematical equations from scholarly articles to develop a model for the terpene precursors in the mevalonate (MVA) pathway to terpene production',
            ],
            image: IgemImage,
            imageClass: 'novascan-image'
        },
        {
            position: 'Software Developer and Medical Technician',
            company: 'Guelph Eye MD Clinic \u00B7 Internship',
            location: 'Guelph, ON',
            date: 'Jan 2023 - Apr 2023',
            description: [
                'Designed and developed a user-friendly website using HTML5, CSS, Bootstrap 5, JavaScript, and jQuery for styling and cross-device compatibility.',
                'Implemented a C# and PowerShell script to automate daily staff role assignment by extracting data from a text file generated by PS Suite EMR.',
                'Troubleshooted and resolved software and hardware issues on ophthalmic diagnostic machines (OCT, autorefractor machine, corneal topographer machine) to ensure optimal performance.'
            ],
            image: GemImage,
            imageClass: 'gem-image'
        },
        {
            position: 'System Support Officer (SSO) - Programmer',
            company: 'Attorney General \u00B7 Internship',
            location: 'Toronto, ON',
            date: 'May 2022 - Aug 2022',
            description: [
                'Optimized PL/SQL queries and scripts in Toad for Oracle to improve run-time efficiency.',
                'Created drillthrough, drilldown, and workflow reports using Visual Studio and SSRS for upper management and members of the Ontario Public Service.',
                'Mapped workflows for OPS software and resolved conflicts using C# and object-oriented programming to ensure high functionality.'
            ],
            image: AttorneyImage,
            imageClass: 'attorney-image'
        },
    ]

    return (
        <>
            <div className='container career-page'>
                <div className='text-zone'>
                    <h1>
                        <AnimatedLetters
                            letterClass={letterClass}
                            strArray={['M', 'y', ' ', 'C', 'a', 'r', 'e', 'e', 'r']}
                            idx={15}
                        />
                    </h1>
                </div>
                <div className='experiences-container'>
                    {experiences.map((exp, idx) => (
                        <div key={idx} className='experience-block'>
                            <span className='experience-img-block'>
                                <img src={exp.image} alt={exp.company} className={exp.imageClass} />
                            </span>
                            <span className='experience-text-block'>
                                <span className='experience-institution'>
                                    {exp.company}
                                    <span style={{ float: 'right' }}>{exp.date}</span>
                                </span>
                                <span className='experience-position'>
                                    {exp.position}
                                    <span style={{ float: 'right' }}>{exp.location}</span>
                                </span>
                                <span className='experience-body'>
                                    <ul>
                                        {exp.description.map((line, index) => (
                                            <li key={index}>{line}</li>
                                        ))}
                                    </ul>
                                </span>
                            </span>
                        </div>
                    ))}
                </div>
            </div>
            <Loader type="pacman" />
        </>
    )
}

export default Career
